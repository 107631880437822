import pointGestionApi from "@/api/pointGestionApi"

export async function confirmDeletePointGestion(pointGestionId, pointGestionNom, deleteCallback = null) {
    let {isConfirmed} = await this.$swal({
        title: 'Supprimer Point Gestion',
        text: `Etes-vous sûr de vouloir supprimer le Point Gestion ${pointGestionNom}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmer',
        cancelButtonText: "Annuler",
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
    })

    if(isConfirmed)
        await this.deletePointGestion(pointGestionId, deleteCallback);
}

export async function deletePointGestion(pointGestionId, deleteCallback = null) {
    if(this.hasOwnProperty("deleting"))
        this.deleting = true;
    try {
        await pointGestionApi.deletePointGestion(pointGestionId);
        this.showToast("success", "Point Gestion supprimé", "CheckIcon")
        if(deleteCallback != null && typeof deleteCallback === "function")
            deleteCallback(pointGestionId);
        
    } catch(e) {
        console.log("e", e)
        this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
    }
    finally {
        if(this.hasOwnProperty("deleting"))
            this.deleting = false;
    }
}