<template>
    <b-row>
        <b-col lg="12">
            <b-card>
                <!-- table -->
                <vue-good-table
                    :columns="columns"
                    :rows="pointGestions"
                    :pagination-options="{enabled: true, perPage:pageLength}"
                    :initialSortBy="initialSortBy"
                    :theme="isDark ? 'nocturnal' : ''"
                    styleClass="vgt-table bordered tableau"
                    >
                >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'action'" class="text-nowrap">
                            <b-button
                                size="sm"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="success"
                                class="btn-icon"
                                @click="editPointGestion(props.row.id)"
                            >
                                <feather-icon icon="Edit2Icon" size="1x"/>
                            </b-button>

                            <b-button
                                size="sm"
                                v-if="deletable(props.row)"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="danger"
                                class="btn-icon ml-1"
                                @click="confirmDeletePointGestion(props.row.id, `du ${moment(props.row.dateComptabilise).format('DD/MM/YYYY')}`, removePointGestionFromList)"
                            >
                                <feather-icon icon="TrashIcon" size="1x"/>
                            </b-button>
                        </span>

                        <span v-else-if="props.column.field === 'date'" class="text-nowrap">
                            {{formatDate(props.row.date)}}
                        </span>

                        <span v-else-if="props.column.field === 'totalAchats'" class="text-nowrap">
                            {{formatNumber(props.row.totalAchats)}}
                        </span>
                        <span v-else-if="props.column.field === 'totalVentes'" class="text-nowrap">
                            {{formatNumber(props.row.totalVentes)}}
                        </span>

                        <span v-else-if="props.column.field === 'dateComptabilise'" class="text-nowrap">
                            {{moment(props.row.dateComptabilise).format("DD/MM/YYYY")}}
                        </span>

                        <template v-else-if="props.column.field === 'pointMensuel'" class="text-nowrap">
                            <div class="text-center text-primary"><feather-icon icon="CheckCircleIcon" size="18" v-if="props.row.pointMensuel" /></div>
                        </template>
                        
                        <template v-else-if="props.column.field === 'statut'">
                            <div  class="text-nowrap" :style="{backgroundColor: props.row.statut.color}">{{props.row.statut.libelle}}</div>
                        </template>
                    </template>

                    <template #emptystate>
                        <div class="text-center">Aucun point de gestion correspondant</div>
                    </template>

                    <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['25', '50', '100']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap "> sur {{ props.total }} Points de Gestion </span>
                        </div>
                        <div>
                            <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
                </vue-good-table>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import 'vue-good-table/dist/vue-good-table.css'
    import { VueGoodTable } from 'vue-good-table'
    import decMontantsMixin from "@/views/setups/decMontantsMixin"

    import pointGestionApi from "@/api/pointGestionApi"
    import { confirmDeletePointGestion, deletePointGestion } from "@/views/setups/pointGestion"
    import useAppConfig from '@core/app-config/useAppConfig'
    import { computed } from '@vue/composition-api'
    import { formatNumber } from "@/services/utils"

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import moment from 'moment'

    export default {
        mixins: [decMontantsMixin],
        props: {
            context: {default: null},
            decs: {default: []},
            comptas: {default: () => []}
        },

        components: {
            VueGoodTable,
            ToastificationContent
        },

        data() {
            return {
                pointGestions: [],
                initialSortBy: [{field: 'nom', type: 'asc'}],
                pageLength: 25,
                columns: [
                    {
                        label: 'Date',
                        field: 'date',
                    },
                    {
                        label: 'Ventes annoncés',
                        field: 'totalVentes',
                    },
                    {
                        label: 'Débours annoncés',
                        field: 'totalAchats',
                    },
                    
                    {
                        label: 'Point Mensuel',
                        field: 'pointMensuel',
                    },
                    {
                        label: 'Date Comptabilisée',
                        field: 'dateComptabilise',
                    },
                    {
                        label: 'Statut',
                        field: 'statut',
                        filterOptions: {enabled: true, placeholder: 'Statut...', filterDropdownItems: [], filterFn: (selected, code) => selected.code == code},
                        tdClass: this.tdClassFunc,
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        width: '10%',
                        sortable: false
                    },
                ],
            }
        },

        beforeMount() {
            this.refreshPointGestions()
        },

        setup() {
            const { skin } = useAppConfig()
            const isDark = computed(() => skin.value === 'dark')

            return { skin, isDark, deletePointGestion, confirmDeletePointGestion, formatNumber, moment}
        },

        methods: {

            tdClassFunc(row) {
                return row.statut.code
            },

            formatDate(date) {
                return moment(date).format("DD/MM/YYYY");
            },

            async refreshPointGestions() {
                this.pointGestions = await pointGestionApi.getPointGestions(this.context);
            },

            editPointGestion(pointGestionId) {
                this.$router.push({path: `/pointGestions/${pointGestionId}/edit` })
            },

            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },

            deletable(pointGestion) {
                // TODO => que l'utilisateur qui puisse supprimer des points de gestion
                return pointGestion.statut.code == "BROUILLON" /* && (!this.showDecsOnlyUser || dec.userValidationChargeAffaire.id == this.user.id) */
            },

            removePointGestionFromList(pointGestionId) {
                let index = this.pointGestions.findIndex(pg => pg.id == pointGestionId)
                if(index != -1)
                    this.pointGestions.splice(index, 1)
            }
        }
    }
</script>

<style>
    .dropdown > button{
        padding: 0 !important;
    }
</style>