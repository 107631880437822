import {callUrl} from "./api";

export default {
    async getAffaires(data = {}) {
        try {
            let res = await callUrl("GET", 'api/affaires', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getAffaire(affaireId) {
        try {
            let res = await callUrl("GET", `api/affaires/${affaireId}`)
            return res;
        } catch(e) {
            return null;
        }
    },

    async addAffaire(data) {
        return await callUrl("POST", `api/affaires`, data);
    },

    async patchAffaire(affaireId, data) {
        return await callUrl("PATCH", `api/affaires/${affaireId}`, data);
    },

    async deleteAffaire(affaireId) {
        return await callUrl("DELETE", `api/affaires/${affaireId}`);
    },
}