import { extend, localize } from 'vee-validate'
import moment from "moment"

const rule_after = {
    params: ["otherValue"],
    validate: (value, { otherValue }) => {

        if (value === null || value === '' | otherValue === null || otherValue === '')
            return true;
        let firstDate = moment(otherValue);
        let date = moment(value);
        if (date.isSameOrAfter(firstDate))
            return true;
        return false;
    },
    message:
        "La date doit être supérieure à {otherValue}."
}

export const after = extend('after', rule_after);
