<template>
    <b-row>
        <b-col lg="12">
            <b-card>
                <!-- table -->
                <vue-good-table
                    ref="vgt"
                    :columns="columns"
                    :rows="comptabilites"
                    :pagination-options="{enabled: true, perPage:pageLength}"
                    :initialSortBy="initialSortBy"
                    :theme="isDark ? 'nocturnal' : ''"
                    styleClass="vgt-table bordered tableau"
                    >
                >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'montantHT'" class="text-nowrap">
                            {{formatNumber(parseFloat(props.row.montantHT) * (props.row.type.code == "VT" ? 1 : -1))}}
                        </span>

                        <template v-else-if="props.column.field === 'type'">
                            <span>{{`${props.row.type.libelle}`}}</span>
                        </template>
                    </template>

                    <template #emptystate>
                        <div class="text-center"> Aucune comptabilité disponible</div>
                    </template>

                    <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['25', '50', '100']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap "> sur {{ props.total }} Comptabilités </span>
                        </div>
                        <div class="d-flex  align-items-center">
                            <div class="montant-total">Montant Total = {{ formatNumber(total) }}</div>
                            <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
                </vue-good-table>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import 'vue-good-table/dist/vue-good-table.css'
    import { VueGoodTable } from 'vue-good-table'

    import comptabiliteApi from "@/api/comptabiliteApi"
    import {mapState, mapMutations} from "vuex"

    import useAppConfig from '@core/app-config/useAppConfig'
    import { computed } from '@vue/composition-api'
    import { formatNumber } from "@/services/utils"
    import miscApi from "@/api/miscApi"

    import moment from 'moment'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        props: {
            context: {default: () => {}},
        },

        components: {
            VueGoodTable,
            ToastificationContent
        },

        data() {
            return {
                initialSortBy: [{field: 'nom', type: 'asc'}],
                pageLength: 25,
                columns: [
                    {
                        label: 'Date',
                        field: 'dateEcriture',
                    },
                    {
                        label: 'Type',
                        field: 'type',
                        filterOptions: {enabled: true, placeholder: 'Type...', filterDropdownItems: [], filterFn: (selected, code) => selected.code == code},
                    },
                    {
                        label: 'Libellé',
                        field: 'libelle',
                        filterOptions: {enabled: true,placeholder: 'Libelle ...',},
                    },
                    {
                        label: 'Qté',
                        field: 'qty',
                        filterOptions: {enabled: true,placeholder: 'Qté ...',},
                    },
                    {
                        label: 'Montant',
                        field: 'montantHT',
                        filterOptions: {enabled: true,placeholder: 'Montant ...',},
                    },
                ],
                total : 0
            }
        },

        beforeMount() {
            this.refreshComptabilites()
            this.refreshTypes();
        },

        setup() {
            const { skin } = useAppConfig()
            const isDark = computed(() => skin.value === 'dark')

            return { skin, isDark, formatNumber}
        },

        computed: {
            ...mapState({
                storeComptabilites: state => state.data.comptabilites,
                types: state => state.data.comptabiliteTypes,
            }),

            comptabilites: {
                get() {return this.storeComptabilites},
                set(comptabilites) {this.setComptabilites(comptabilites)}
            },
        },
        methods: {
            ...mapMutations("data", ["setComptabilites", "removeComptabiliteFromList"]),
            ...mapMutations("data", {
                setTypes: "setComptabiliteTypes",
            }),

            async refreshComptabilites() {
                this.comptabilites = await comptabiliteApi.getComptabilites(this.context);
            },

            editComptabilite(comptabiliteId) {
                this.$router.push({path: `/comptabilites/${comptabiliteId}/edit` })
            },

            async refreshTypes() {
                let types = await miscApi.getComptabiliteTypes();
                this.setTypes(types);
                this.columns.find(c => c.field == "type").filterOptions.filterDropdownItems = this.types.map(s => ({text: s.libelle, value: s.code}))
            },

            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },
        },
        mounted() {
            this.$watch(
                () => {
                    return this.$refs.vgt.filteredRows;
                },
                (val) => {
                    this.total = val[0].children.reduce((accumulator, row) => {return accumulator + (row.montantHT)},0)
                }
            )
        }
    }
</script>

<style>
    .dropdown > button{
        padding: 0 !important;
    }

    .montant-total {
        margin-top: 1rem;
        padding-right: 2rem;
    }
</style>