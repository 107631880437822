import {mapMutations, mapGetters} from "vuex";

export default {
    props: {
        // si la liste est affichée dans un item ouvert d'une autre liste
        // (cas liste des decs dans une affaire, on souhaite garder le filtre de cette liste de dec sans perdre
        // le filtre de la recherche des affaires, en cas de retour encore)
        isNested: {default: false}
    },

    computed: {
        ...mapGetters("data", ["getFiltersForContext"]),
        fullVgtContext() {return this.isNested ? this.vgtContext + "-nested" : this.vgtContext}
    },
    
    mounted() {
        let filters = this.getFiltersForContext(this.vgtContext);
        if(filters != null) {
            Object.entries(filters).forEach(([filterField, filterValue]) => {
                let index = this.columns.findIndex(({field}) => field == filterField)
                this.$set(this.columns[index].filterOptions, 'filterValue', filterValue)
            })
            return this.isNested ? this.resetFilters(this.vgtContext) : this.resetAllFilters()
        }
    },

    methods: {
        ...mapMutations("data", ["setListFilters", "resetFilters", "resetAllFilters"]),
        saveFilters() {
            this.setListFilters({context: this.fullVgtContext, filters: this.$refs[this.fullVgtContext].columnFilters})
        }
    }
}