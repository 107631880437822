import {callUrl} from "./api";

export default {
    async getDocuments(data = {}) {
        try {
            let res = await callUrl("GET", 'api/documents', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async addDocument(decIri, file2Add) {
        let datas = new FormData();
        datas.append("dec", decIri);
        datas.append("nom", file2Add.name);
        datas.append("file", file2Add);
        return await callUrl("POST", `api/documents`, datas, [201],{'content-type': "multipart/form-data"});
    },

    async deleteDocument(documentId) {
        return await callUrl("DELETE", `api/documents/${documentId}`);
    },
}