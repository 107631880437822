import {callUrl} from "./api";

export default {
    async getUtilisateurs(params = null) {
        try {
            let res = await callUrl("GET", 'api/users', params)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getUtilisateur(utilisateurId) {
        try {
            let res = await callUrl("GET", `api/users/${utilisateurId}`)
            return res;
        } catch(e) {
            return null;
        }
    },

    async patchUtilisateur(utilisateurId, data) {
        return await callUrl("PATCH", `api/users/${utilisateurId}`, data);
    },

    async deleteUtilisateur(utilisateurId) {
        return await callUrl("DELETE", `api/users/${utilisateurId}`);
    },

    async addUtilisateur(data) {
        return await callUrl("POST", `api/users`, data);
    }
}