import {callUrl} from "./api";

export default {
    async getDecs(data = {}) {
        try {
            let res = await callUrl("GET", 'api/decs', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getDec(decId) {
        try {
            let res = await callUrl("GET", `api/decs/${decId}`)
            return res;
        } catch(e) {
            return null;
        }
    },

    async patchDec(decId, data) {
        return await callUrl("PATCH", `api/decs/${decId}`, data);
    },

    async deleteDec(decId) {
        return await callUrl("DELETE", `api/decs/${decId}`);
    },

    async addDec(data) {
        return await callUrl("POST", `api/decs`, data);
    },

    async addPvao(data) {
        return await callUrl("POST", `api/pvaos`, data);
    },

    async getPvaos(data = {}) {
        try {
            let res = await callUrl("GET", 'api/pvaos', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    }
}