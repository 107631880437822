import {callUrl} from "./api";

export default {
    async getPointGestions(data = {}) {
        try {
            let res = await callUrl("GET", 'api/point_gestions', data)
            return res['hydra:member'];
        } catch(e) {
            return [];
        }
    },

    async getPointGestion(pointGestionId) {
        try {
            let res = await callUrl("GET", `api/point_gestions/${pointGestionId}`)
            return res;
        } catch(e) {
            return null;
        }
    },

    async addPointGestion(data) {
        return await callUrl("POST", `api/point_gestions`, data);
    },

    async patchPointGestion(pointGestionId, data) {
        return await callUrl("PATCH", `api/point_gestions/${pointGestionId}`, data);
    },

    async deletePointGestion(pointGestionId) {
        return await callUrl("DELETE", `api/point_gestions/${pointGestionId}`);
    },

    async getLastPointGestion(affaireId) {
        try {
            let res = await callUrl("GET", `api/pointgestion/last/${affaireId}`)
            return res;
        } catch(e) {
            return null;
        }
    }
}