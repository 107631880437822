export default {
    computed: {
        dataMontantsEdit() {
            return [
                {
                    title: "Fournitures et Sous-Traitance",
                    fields: [
                        {
                            id: "ligneMateriel",
                            title: "Matériel / Achat",
                            qty: {field: "ligneMaterielQte", value: this.ligneMaterielQte},
                            cu: {field: "ligneMaterielCU", value: this.ligneMaterielCU},
                        },
                        {
                            id: "ligneSousTraitance",
                            title: "Sous-Traitance",
                            qty: {field: "ligneSousTraitanceQte", value: this.ligneSousTraitanceQte},
                            cu: {field: "ligneSousTraitanceCU", value: this.ligneSousTraitanceCU},
                        },
                        {
                            id: "ligneEngage",
                            title: "Engagé",
                            qty: {field: "ligneEngageQte", value: 'ligneEngageQte' in this ? this.ligneEngageQte : 0},
                            cu: {field: "ligneEngageCU", value: 'ligneEngageCU' in this ? this.ligneEngageCU : 0},
                        },
                    ]
                },
                {
                    title: "Main d'Œuvre",
                    fields: [
                        {
                            id: "ligneMainOeuvreLocale",
                            unit: "H",
                            title: "Main d'œuvre locale",
                            qty: {field: "ligneMainOeuvreLocaleQte", value: this.ligneMainOeuvreLocaleQte},
                            cu: {field: "ligneMainOeuvreLocaleCU", value: this.ligneMainOeuvreLocaleCU},
                        },
                        {
                            id: "ligneMainOeuvreDeplacee",
                            unit: "H",
                            title: "Main d'œuvre déplacée",
                            qty: {field: "ligneMainOeuvreDeplaceeQte", value: this.ligneMainOeuvreDeplaceeQte},
                            cu: {field: "ligneMainOeuvreDeplaceeCU", value: this.ligneMainOeuvreDeplaceeCU},
                        },
                        {
                            id: "ligneMainOeuvreInterim",
                            unit: "H",
                            title: "Main d'œuvre intérim",
                            qty: {field: "ligneMainOeuvreInterimQte", value: this.ligneMainOeuvreInterimQte},
                            cu: {field: "ligneMainOeuvreInterimCU", value: this.ligneMainOeuvreInterimCU},
                        },
                        {
                            id: "ligneEtudes",
                            unit: "H",
                            title: "Etudes",
                            qty: {field: "ligneEtudesQte", value: this.ligneEtudesQte},
                            cu: {field: "ligneEtudesCU", value: this.ligneEtudesCU},
                        },
                        {
                            id: "ligneEssais",
                            unit: "H",
                            title: "Essais",
                            qty: {field: "ligneEssaisQte", value: this.ligneEssaisQte},
                            cu: {field: "ligneEssaisCU", value: this.ligneEssaisCU},
                        },
                    ],
                },
                {
                    title: "Encadrement et Frais de Chantier",
                    hideQty: true,
                    fields: [
                        {
                            id: "ligneTotalMaitrise",
                            title: "Total Maîtrise Chantier",
                            cu: {field: "ligneTotalMaitriseCU", value: this.ligneTotalMaitriseCU},
                        },
                        {
                            id: "ligneTotalFraisChantier",
                            title: "Total Frais de chantier",
                            cu: {field: "ligneTotalFraisChantierCU", value: this.ligneTotalFraisChantierCU},
                        },
                        {
                            id: "ligneTotalMaitriseEtude",
                            title: "Total Maîtrise Etude",
                            cu: {field: "ligneTotalMaitriseEtudeCU", value: this.ligneTotalMaitriseEtudeCU},
                        },
                        {
                            id: "ligneTotalMaitriseChargeAffaire",
                            title: "Total Maîtrise Chargé Affaire",
                            cu: {field: "ligneTotalMaitriseChargeAffaireCU", value: this.ligneTotalMaitriseChargeAffaireCU},
                        },
                    ]
                },
                {
                    hideQty: true,
                    title: "Divers",
                    fields: [
                        {
                            id: "ligneOutillage",
                            title: "Outillage",
                            cu: {field: "ligneOutillageCU", value: this.ligneOutillageCU},
                        },
                        {
                            id: "ligneDivers",
                            title: "Divers",
                            cu: {field: "ligneDiversCU", value: this.ligneDiversCU},
                        },
                    ],
                }
            ]
        }
    },
    methods: {
        updateMontant(field, value) {
            this[field] = value;
        },
    }
}