import {callUrl} from "./api";

export default {
    async getComptabilites(data = {}) {
        try {
            let url = "api/comptabilites";
            let res = await callUrl("GET", url, data)
            return res['hydra:member'];
        } catch(e) {
            console.log("e", e)
            return [];
        }
    },
}