<template>
    <div>
        <loading-card v-if="loading">
            <template v-if="saving">Enregistrement en cours ...</template>
            <template v-else-if="deleting">Suppression en cours ...</template>
            <template v-else>Chargement de l'affaire ...</template>
        </loading-card>

        <div v-else>
            <b-form @keydown.enter.prevent>
                <validation-observer ref="simpleRules">
                    <b-row>
                        <b-col lg="12">
                            <b-form-group 
                                label-for="titre"
                                label-cols-lg="3"
                                label-cols-sm="12"
                                label-size="lg"
                            >
                                <div slot="label">
                                Affaire n° <b>{{affaire != null && affaire.num != null && affaire.num != "" ? affaire.num : '-'}}</b>
                                </div>
                                <validation-provider #default="{ errors }" name="Titre" rules="required">
                                    <b-form-input 
                                        v-model="titre"
                                        id="titre"
                                        placeholder="Titre"
                                        size="lg"
                                        :state="errors.length > 0 ? false : null"
                                        :disabled="!isFormEditable"
                                    />
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-card title="Informations" class="card-info">
                        <b-row>
                            <b-col lg="4" sm="12">
                                <b-form-group label="Client" label-for="client">
                                    <validation-provider #default="{ errors }" name="Client" rules="required">
                                        <v-select
                                            v-model="client"
                                            :options="clients"
                                            :disabled="!isFormEditable"
                                            :filter="filterClients"
                                            :clearable="false"
                                            :filterable="true"
                                            :class="errors.length > 0 ? 'danger' : ''"
                                            placeholder="Rechercher un client"
                                        >
                                            <template #no-options="{search, loading}">
                                                {{search.length < 2 ? "Rechercher un client" : "Aucun client correspondant"}}
                                            </template>
                                            <template #option="{nom}">{{nom}}</template>
                                            <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                            <template #selected-option="{nom}">{{nom}}</template>
                                        </v-select>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" sm="12">
                                <b-form-group label="Agence" label-for="agence">
                                    <validation-provider #default="{ errors }" name="Agence" rules="required">
                                        <v-select
                                            v-model="agence"
                                            :options="agences"
                                            :disabled="!isFormEditable"
                                            :filter="filterAgences"
                                            :clearable="false"
                                            :filterable="true"
                                            :class="errors.length > 0 ? 'danger' : ''"
                                            placeholder="Rechercher une agence"
                                        >
                                            <template #no-options="{search, loading}">
                                                {{search.length < 2 ? "Rechercher un client" : "Aucun client correspondant"}}
                                            </template>
                                            <template #option="{nom, numero}">{{numero}} - {{nom}}</template>
                                            <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                            <template #selected-option="{nom, numero}">{{numero}} - {{nom}}</template>
                                        </v-select>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" sm="12">
                                <b-form-group label="Chargé d'affaire" label-for="userChargeAffaire">
                                    <validation-provider #default="{ errors }" name="Chargé Affaire" rules="required">
                                        <b-row>
                                            <b-col lg="12">
                                                <v-select
                                                    v-model="userChargeAffaire"
                                                    :options="usersChargeAffaireMatching"
                                                    :clearable="false"
                                                    :filterable="true"
                                                    :filter="filterUsers"
                                                    :class="errors.length > 0 ? 'danger' : ''"
                                                    :disabled="!isFormEditable || (showDecsOnlyUser && userChargeAffaire != null && user.id == userChargeAffaire.id)"
                                                >
                                                    <template #no-options="{search, loading}">
                                                        {{"Rechercher un utilisateur"}}
                                                    </template>
                                                    <template #option="{firstname, lastname, username}">{{firstname}} {{lastname}} - {{username}}</template>
                                                    <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                                    <template #selected-option="{firstname, lastname, username}">{{firstname}} {{lastname}} - {{username}}</template>
                                                </v-select>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </b-col>
                                        </b-row>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col lg="6" sm="12">
                                <b-form-group label="Date Début" label-for="dateDebut">
                                    <validation-provider #default="{ errors }" name="Date Début" vid="datedebut">
                                        <b-form-datepicker
                                            locale="fr" 
                                            v-model="dateDebut"
                                            :disabled="!isFormEditable"
                                            id="dateDebut"
                                            placeholder="Date Début"
                                            :date-format-options="{day: '2-digit', year: 'numeric', month: '2-digit'}"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <b-form-group label="Date Fin" label-for="dateFin">
                                    <validation-provider #default="{ errors }" name="Date Fin" :rules="{after:['@datedebut']}">
                                        <b-form-datepicker
                                            locale="fr" 
                                            v-model="dateFin"
                                            :disabled="!isFormEditable"
                                            id="dateFin"
                                            placeholder="Date Fin"
                                            :date-format-options="{day: '2-digit', year: 'numeric', month: '2-digit'}"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col lg="6" sm="12">
                                <b-form-group label="Statut" label-for="statut">
                                    <validation-provider #default="{ errors }" name="Type" rules="required">
                                        <b-row>
                                            <b-col lg="12">
                                                <v-select
                                                    v-model="statut"
                                                    :options="affaireStatuts"
                                                    :clearable="false"
                                                    :filterable="true"
                                                    :filter="filterType"
                                                    :class="errors.length > 0 ? 'danger' : ''"
                                                    :disabled="!canChangeStatut || (!isFormEditable && affaire != null && affaire.statut.code != 'TECHNIQUE')"
                                                    :selectable="statutSelectable"
                                                >
                                                    <template #no-options="{search, loading}">
                                                        {{"Rechercher un statut"}}
                                                    </template>
                                                    <template #option="{libelle}">{{libelle}}</template>
                                                    <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                                    <template #selected-option="{libelle}">{{libelle}}</template>
                                                </v-select>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </b-col>
                                        </b-row>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            
                            <b-col lg="6" sm="12">
                                <b-form-group label="Type" label-for="type">
                                    <validation-provider #default="{ errors }" name="Type" rules="required">
                                        <b-row>
                                            <b-col lg="12">
                                                <v-select
                                                    v-model="type"
                                                    :options="affaireTypes"
                                                    :clearable="false"
                                                    :filterable="true"
                                                    :filter="filterType"
                                                    :class="errors.length > 0 ? 'danger' : ''"
                                                    :disabled="!isFormEditable"
                                                >
                                                    <template #no-options="{search, loading}">
                                                        {{"Rechercher un type"}}
                                                    </template>
                                                    <template #option="{libelle}">{{libelle}}</template>
                                                    <b-spinner  small slot="spinner" slot-scope="{loading}" v-show="loading" type="grow" variant="primary"/>
                                                    <template #selected-option="{libelle}">{{libelle}}</template>
                                                </v-select>
                                                <small class="text-danger">{{errors[0]}}</small>
                                            </b-col>
                                        </b-row>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </validation-observer>

                <b-row>
                    <b-col lg="12">
                        <b-button variant="gradient-success" type="submit" @click.prevent="validationForm" class="mr-1" v-if="!loading && affaire.statut.code != 'FINANCIER'">
                            <feather-icon icon="CheckIcon" class="mr-50"/>
                            <span class="align-middle">Enregistrer</span>
                            <b-spinner small class="ml-50" v-show="saving"></b-spinner>
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>

            <b-row class="mt-1">
                <b-col lg="4" sm="12">
                    <b-card title="Entrée de Commande" class="card-other" :style="sameHeightStyle">
                        <b-row>
                            <b-col lg="3">
                                PV Budget : 
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(totalDecsVente)}}</b>
                            </b-col>
                            <b-col lg="3">
                                MB Budget :
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(totalDecsVente - totalDecsDebours)}}</b>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col lg="3">
                                PR Budget :
                            </b-col>
                            <b-col lg="3" class="text-right">
                               <b>{{formatNumber(totalDecsDebours)}}</b>
                            </b-col>
                            <b-col lg="3">
                               % MB Budget :
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(margeDecs, '%')}}</b>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col lg="4" sm="12">
                    <b-card :title="'Comptabilisé (sans engagés)'" class="card-other" :style="sameHeightStyle">
                        <b-row>
                            <b-col lg="3">
                                PV Pg M :
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(totalComptabiliseVente)}}</b>
                            </b-col>
                            <b-col lg="3">
                                MB PG M :
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(totalComptabiliseVente - totalComptabiliseDebours)}}</b>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col lg="3">
                                PR Pg M :
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(totalComptabiliseDebours)}}</b>
                            </b-col>
                            <b-col lg="3">
                               % MB PG M :
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(totalComptabiliseVente == 0 ? 0 : 100 - (100 * totalComptabiliseDebours / totalComptabiliseVente), "%")}}</b>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6">
                                Avancement Théorique :
                            </b-col>
                            <b-col lg="6" class="text-right">
                                <b>{{formatNumber(avancementTheorique, "%")}}</b>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>

                <b-col lg="4" sm="12">
                    <b-card :title="'Point de Gestion : ' + lastPointGestionDate" class="card-other" :style="sameHeightStyle">
                        <b-row>
                            <b-col lg="3">
                                PV Pg M :
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(totalVentesLastPG)}}</b>
                            </b-col>
                            <b-col lg="3">
                                MB PG M :
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(totalVentesLastPG - totalResteLastPG)}}</b>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col lg="3">
                                PR Pg M :
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(totalResteLastPG)}}</b>
                            </b-col>
                            <b-col lg="3">
                               % MB PG M :
                            </b-col>
                            <b-col lg="3" class="text-right">
                                <b>{{formatNumber(totalVentesLastPG == 0 ? 0 : 100 - (100 * totalResteLastPG / totalVentesLastPG), "%")}}</b>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col lg="8">
                                Avancement Annoncé / Théorique :
                            </b-col>
                            <b-col lg="4" class="text-right">
                                <b>{{formatNumber(avancementChantierLastPG, "%")}}</b>
                                /
                                <b>{{formatNumber(avancementTheoriqueLastPG, "%")}}</b>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            
            <b-row v-if="statut != null && statut.code != 'FINANCIER'">
                <b-col offset-lg="4" lg="3">
                    <div class="d-flex flex-row justify-content-center align-items-center" style="height: 100%;">
                        <b-card-text class="text-center">
                            <b-container>
                                <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" :to="`/pointGestions/${affaire.id}/add`">
                                    <feather-icon class="text-primary mr-50 align-middle" icon="PlusSquareIcon" size="30"/>
                                    <span class="text-primary align-middle">Ajouter Point de Gestion</span>
                                </b-button>
                            </b-container>
                        </b-card-text>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col lg="12">
                    <b-card no-body class="card-other">
                        <b-tabs card pills :class="'onglets'">
                            <b-tab title="Points de Gestion" active>
                                <point-gestions 
                                    :context="{affaire: this.idAffaire}"
                                    :decs="decs" :comptas="comptabilites"
                                />
                            </b-tab>
                            <b-tab title="Liste DECs">
                                <decs @refresh-decs="decs = $event" :context="{affaire: this.idAffaire}" :isNested="true">
                                    <span slot="add" />
                                </decs>
                            </b-tab>
                            <b-tab title="Comptabilité">
                                <b-card-text>
                                    <comptabilite :context="{affaire: this.idAffaire}"/>
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="12" lg="6" md="8">
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="goBack" class="mr-1">
                        <feather-icon icon="ListIcon" class="mr-50"/>
                        <span class="align-middle">Retour</span>
                    </b-button>
                </b-col>

                <!-- <b-col sm="12" lg="6" md="4" :class="deleteClass" v-if="isFormEditable && (!showDecsOnlyUser || isChargeAffaire)">
                    <b-button variant="gradient-danger" @click="confirmDeleteAffaire(affaire.id, affaire.num, goBack)">
                        <feather-icon icon="TrashIcon" class="mr-50"/>
                        <span class="align-middle">Supprimer</span>
                    </b-button>
                </b-col> -->
            </b-row>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex"
    import LoadingCard from "@/views/GDLoadingCard"
    import {computedFactory, storeComputedFactory} from "@/services/computedFactory"
    import { computed, ref } from '@vue/composition-api'
    import { confirmDeleteAffaire, deleteAffaire } from "@/views/setups/affaire"
    import expectedFields from "./affaireFields"

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import { after } from '@/libs/validations'
    import vSelect from 'vue-select'
    import _ from "lodash"

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Cleave from 'vue-cleave-component'

    import clientApi from "@/api/clientApi"
    import agenceApi from "@/api/agenceApi"
    import affaireApi from "@/api/affaireApi"
    import miscApi from "@/api/miscApi"
    import utilisateurApi from "@/api/utilisateurApi"
    import docApi from "@/api/documentApi"
    import {APP_URL, downloadFile} from "@/api/api"

    import {formatNumber} from "@/services/utils"
    import {filterArray} from "@/services/utils"

    import Decs from "../lists/Decs.vue"
    import Comptabilite from "../lists/Comptabilite.vue"
    import PointGestions from "../lists/PointGestions.vue"

    import moment from "moment"
    
    export default {
        components: {
            LoadingCard,
            ValidationProvider,
            ValidationObserver,
            ToastificationContent,
            vSelect,
            Cleave,
            Decs,
            Comptabilite,
            PointGestions
        },

        data() {
            return {
                saving: false,
                deleting: false,
                clientsMatching: [],

                loadCompta: false,
                loadDecs: false,

                lastPointGestionDirection: null,
                /* lastPointGestionChargeAffaire: null, */
                decs: [],
            }
        },

        beforeMount() {
            if(this.$route.params.hasOwnProperty("affaireId"))
                this.fetchAffaire();

            this.refreshStatuts();
            this.refreshTypes();
            this.refreshClients();
            this.refreshAgences();
        },

        setup(props, {root}) {
            // TODO modifier le computed factory pour pouvoir spécifier des types pour les attributs :
            // resource, pour savoir qu'on doit réduire l'objet à juste son IRI (apiPlatform), float pour savoir
            // que l'on doit le convertir en float (sinon erreur enregistrement api platform)
            const currentBreakPoint = computed(() => root.$store.getters['app/currentBreakPoint'])
            // TODO voir si on ne peut pas récupérer tous les fields attendus pour un groupe de serialization via APi platform
            const storeFields = ["clients", "agences", "affaireStatuts", "pointGestionStatuts", "users", "affaireTypes"]
            let affaireBase = null;
            let creatingBase = !root.$route.params.hasOwnProperty("affaireId");
            if(creatingBase) {
                affaireBase = expectedFields.reduce((accumulator, field) => ({...accumulator, [field]: null}), {});
            }


            const affaire = ref(affaireBase);
            const creating = ref(creatingBase)

            return {
                affaire,
                creating,
                expectedFields,
                ...computedFactory(expectedFields, "affaire"),
                ...storeComputedFactory(storeFields, root.$store),
                currentBreakPoint,
                required,
                confirmDeleteAffaire,
                deleteAffaire,
                formatNumber,
                downloadFile
            }
        },

        computed: {
            ...mapState({
                user: state => state.data.user,
                comptabilites: state => state.data.comptabilites,
            }),
            
            ...mapGetters("data", ["showDecsOnlyUser", "isGranted"]),
            isChargeAffaire() {
                return this.isGranted("ROLE_CHARGE_AFFAIRE") && this.userChargeAffaire?.id == this.user.id
            },
            canChangeStatut() {
                return this.affaire != null && this.affaire.statut.code != "FINANCIER" && (this.isGranted("ROLE_COMPTABILITE") || this.isGranted("ROLE_DIRECTION"));
            },
            deleteClass() {
                return (this.currentBreakPoint == "sm" || this.currentBreakPoint == "xs") ? "mt-1" : "text-right";
            },
            sameHeightStyle() {
                return this.currentBreakPoint == "sm" || this.currentBreakPoint == "xs" ? {} : {minHeight: '10.5rem'}
            },
            isFormEditable() {
                return this.canChangeStatut || (this.affaire != null && this.affaire.statut.code == "ENCOURS")
            },
            usersChargeAffaireMatching() {return this.users.filter(user => user.roles.includes("ROLE_CHARGE_AFFAIRE"))},

            lastPointGestionDate() {
                return this.lastPointGestionDirection == null ? "-" : moment(this.lastPointGestionDirection.date).format("DD/MM/YYYY")
            },

            totalDecsVente() {
                return this.decs.reduce((accumulator, dec) => accumulator + parseFloat(dec.prixVenteHT) ,0)
            },

            totalDecsDebours() {
                return this.decs.reduce((accumulator, dec) => accumulator + parseFloat(dec.totalDebours) ,0)
            },

            margeDecs() {
                return this.totalDecsVente > 0 ? 100 * (this.totalDecsVente - this.totalDecsDebours) / this.totalDecsVente : 0;
            },

            totalVentesLastPG() {
                return this.lastPointGestionDirection?.totalVentes ?? 0;
            },
            totalResteLastPG() {
                return this.lastPointGestionDirection?.totalAchats ?? 0;
            },
            avancementChantierLastPG() {
                return this.lastPointGestionDirection?.avancementChantier ?? 0;
            },
            avancementTheoriqueLastPG() {
                return this.lastPointGestionDirection?.avancementTheorique ?? 0;
            },


            totalComptabiliseDebours() {
                return this.comptabilites.reduce((accumulator, compta) => {
                    if(compta.type.mappedMontant != null && compta.type.mappedMontant != "ligneEngage")
                        return accumulator + -1 * compta.montantHT
                    return accumulator;
                }, 0)
            },

            totalComptabiliseVente() {
                return this.comptabilites.reduce((accumulator, compta) => {
                    if(compta.type.code == "VT")
                        return accumulator + compta.montantHT
                    return accumulator;
                }, 0)
            },

            avancementTheorique() {
                return this.totalDecsDebours == 0 ? 0 : (this.totalComptabiliseDebours * 100 / this.totalDecsDebours)
            },

            loading() {
                return this.affaire == null || this.statut == null;
            },

            idAffaire() {
                return this.affaire != null ? this.affaire.id : 0;
            }
        },

        methods: {
            filterType(options, search) {
                return filterArray(options, ["libelle"], search)
            },
            filterClients(options, search) {
                return filterArray(options, ["nom"], search)
            },
            filterAgences(options, search) {
                return filterArray(options, ["code", "nom"], search)
            },
            filterUsers(options, search) {
                return filterArray(options, ["firstname", "lastname", "username"], search)
            },

            goBack() {
                // ! ATTENTION => fonctionne dans le cas retour à la liste. si on utilise le composant Affaire à un autre endroit
                // ! il faudra surement revoir la logique. a garder en tête.
                this.$router.go(-1)
            },

            statutSelectable(option) {
                return this.affaire != null && (this.affaire.statut.code == "ENCOURS" || (option.code == "FINANCIER" && this.affaire.statut.code == "TECHNIQUE") || option.code == this.affaire.statut.code)
            },

            async fetchAffaire() {
                this.affaire = await affaireApi.getAffaire(this.$route.params.affaireId);
                this.lastPointGestionDirection = this.affaire.lastPointGestionDirection;
            },

            async validationForm() {
                let res = await this.$refs.simpleRules.validate()
                                
                if(res)
                    this.save();
                else {
                    this.showToast("danger", "Enregistrement impossible, veuillez vérifier les champs du formulaire", "AlertCircleIcon")
                    this.$nextTick(() => {
                        const el = this.$el.querySelector(".is-invalid");
                        if(el.id == "titre")
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        else
                            el.scrollIntoView(true);
                        return;
                    });
                }
            },
            
            async save() {
                try {
                    let data = this.expectedFields.filter(field => this[field] != this.affaire[field]).reduce((accumulator, currentValue) => ({...accumulator, [currentValue]: this[currentValue]}), {});
                    if(data.hasOwnProperty("statut")) {
                        let text = `Une fois au statut ${data.statut.libelle} l'affaire ne sera plus modifiable et`;
                        text += data.statut.code == "FINANCIER" ? " il ne sera plus possible de créer de nouveaux points de gestion" : " il ne sera plus possible de la lier à des DECs.";
                        let {isConfirmed} = await this.$swal({
                            title: 'Changement de statut',
                            text,
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'Valider',
                            cancelButtonText: "Annuler",
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-outline-danger ml-1',
                            },
                            buttonsStyling: false,
                        })

                        if(!isConfirmed) {
                            return;
                        }
                    }
                    this.saving = true;


                    Object.entries(data).forEach(([key, value]) => {
                        if(value.hasOwnProperty("@id"))
                            data[key] = value['@id']
                    })
                    
                    if(Object.entries(data).length != 0)
                        this.affaire = await affaireApi.patchAffaire(this.affaire.id, data);
                        

                    this.showToast("success", "Affaire mis à jour", "CheckIcon")
                    //this.$router.go(-1);
                } catch(e) {
                    console.log("e", e)
                    /* if(oldStatut != null)
                        this.statut = oldStatut */
                    this.showToast("danger", "Une erreur s'est produite", "AlertCircleIcon")
                }
                finally {
                    this.saving = false;
                }
            },

            // TODO déplacer dans service, en faire une méthode générique
            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },

            async refreshUsers() {
                this.users = await utilisateurApi.getUtilisateurs({active: 1});
            },

            async refreshTypes() {
                this.affaireTypes = await miscApi.getAffaireTypes();
            },

            async refreshStatuts() {
                this.affaireStatuts = await miscApi.getAffaireStatuts();
                this.pointGestionStatuts = await miscApi.getPointGestionStatuts();
            },

            async refreshClients() {
                this.clients = await clientApi.getClients();
            },

            async refreshAgences() {
                this.agences = await agenceApi.getAgences();
            },

            async addFile() {
                if (this.file2Add != null) 
                {
                    try {
                        let newDoc = await docApi.addDocument(this.affaire['@id'], this.file2Add);
                        this.linkedDocuments.push(newDoc);
                    }
                    catch(addFileException) {
                        console.log(addFileException);
                    }
                    this.file2Add = null;
                }
                
            },

            getDocUrl(doc) {
                let url = APP_URL + doc.path;
                return url;
            },
        }

    }
</script>

<style>
    .danger,
    .danger .vs__selected-options,
    .danger .vs__dropdown-toggle {
        color: red;
        border-color: red;
    }
</style>