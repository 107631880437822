export default [
    "titre",
    "statut",
    "type",
    "num",
    "client",
    "agence",
    "dateFin",
    "dateDebut",
    "userChargeAffaire",
]
